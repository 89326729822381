import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "HTTP Cache で求人サイトのスピード改善を試してみた話",
  "date": "2018-08-27T03:48:32.000Z",
  "slug": "entry/2018/08/27/124832",
  "tags": ["medley"],
  "hero": "./2018_08_27.png",
  "heroAlt": "http cache"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`こんにちは、開発本部の楊です。メドレーの社内勉強会「TechLunch」で、前回は、`}<a parentName="p" {...{
        "href": "/entry/2017/06/29/161001"
      }}>{`React の基本`}</a>{`を紹介しましたが、今回は HTTP Cache で、`}<a parentName="p" {...{
        "href": "https://job-medley.com/"
      }}>{`医療介護求人サイト「ジョブメドレー」`}</a>{`のスピード改善ができないか検討した話について、共有しました。`}</p>
    <h1>{`なぜ HTTP Cache について話すことにしたのか`}</h1>
    <p>{`ジョブメドレーには、医療機関や保育園、介護施設などさまざまな事業所の求人が掲載されています。現在、14 万を超える事業部の求人が掲載されており、かつ事業所側で求人原稿を修正することもできるため、求職者側が閲覧するスピードについては、いつも意識して改善に取り組んでいます。`}</p>
    <p>{`その試行錯誤の中で、HTTP Cache を使って改善する方法について、実現性など含めて検証することにしました。`}</p>
    <p>{`今回は、あるページをモデルケースに試してみました。このページは、PC は平均 250ms、モバイルは 180ms になっています。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20180824/20180824171410.png",
      "alt": "20180824171410.png"
    }}></img>
    <p>{`ユーザが該当のページを見たときに「内容に更新がないときはブラウザ側のキャッシュを利用してスピードを最適化する」「ページが更新されていたら、最新の内容をすぐユーザへ反映する」という要件でスピード改善されることを目指すことにしました。`}</p>
    <h1>{`HTTP Cache とは？`}</h1>
    <p><a parentName="p" {...{
        "href": "https://developers.google.com/web/fundamentals/performance/optimizing-content-efficiency/http-caching?hl=ja"
      }}>{`Google Developers`}</a>{`では以下のように説明されています。`}</p>
    <blockquote>
      <p parentName="blockquote">{`ネットワーク経由で情報を取得するには時間もコストもかかります。レスポンスが大きいと、クライアントとサーバ間のラウンドトリップを何度も繰り返す必要があるため、レスポンスが利用可能となってブラウザで処理できるようになるまで時間がかかります。さらに、ユーザ側ではデータの通信コストが発生します。そのため、前に取得したリソースをキャッシュに保存して再使用できることは、パフォーマンスを最適化する上で非常に重要です。`}</p>
    </blockquote>
    <p>{`この機能はほぼ全てのブラウザに対応しており、HTTP ヘッダーで`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`CacheControl`}</code>{`、`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`ETAG`}</code>{`、`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Last-Modified`}</code>{`などを利用して、リソース更新するタイミングなどを細かくコントロール可能です。`}</p>
    <h1>{`Rails での HTTP Cache`}</h1>
    <h3>{`expire_in でキャッシュ`}</h3>
    <p>{`1 時間キャッシュしたい場合は、コントローラにコードを一行書けば大丈夫です。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}>{`expires_in`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1.`}</span>{`hour`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <p>{`これで問題なく 1 時間キャッシュされるのですが、「ページが編集されたら即時にユーザへ反映する」という要件を満たしてはいません。`}</p>
    <h3>{`ETAG を利用`}</h3>
    <p>{`では「ページが更新されたら最新の内容をすぐユーザへ反映して、更新がない時はブラウザ側のキャッシュを利用してスピードを最適化する」を行いたい場合はどうすればよいでしょうか。
ここでは`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`ETAG`}</code>{`を利用しようと思います。`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`ETAG`}</code>{`はページの内容によって、ユニークな文字列を作成して、ブラウザ側でページの更新あるかどうかを判定するものです。`}</p>
    <h3>{`ETAG はどう作成されているか`}</h3>
    <p>{`Rails のデフォルトでは HTTP Cache を有効になっていて、`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`ETAG`}</code>{`を自動的に作成しています。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}>{`header`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string-literal"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`'ETag'`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` Digest`}<span parentName="code" {...{
            "className": "token double-colon punctuation"
          }}>{`::`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`MD5`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`hexdigest`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`body`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <p>{`上のコードのようなイメージで、レスポンス Body から`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`ETAG`}</code>{`を作成します。`}</p>
    <p>{`つまり、サーバから返される HTML ソースの内容が毎回同じであれば、ブラウザは前回キャッシュした内容を読み込むようになります。`}</p>
    <h3>{`サーバレスポンスタイムの短縮`}</h3>
    <p>{`今回の対象ページの機能としてサーバ側では主に二つの部分で時間がかかります。`}</p>
    <ol>
      <li parentName="ol">{`DB、Redis などで画面表示が必要なデータ取得、ロジック処理`}</li>
      <li parentName="ol">{`クライアントに返す HTML のレンダリング`}</li>
    </ol>
    <p>{`対象ページは「HTML のレンダリング」する時間が長かったので、それを改善できれば、サーバレスポンスタイムを一気に短くできます。`}</p>
    <p>{`画面に表示する必要なデータに変換がなければ、HTML のレンダリングをせずにレスポンスを返す機能がないかをさらに調べました。`}</p>
    <h3>{`fresh_when を使う`}</h3>
    <p>{`名前の通り、いつ画面更新するかをコントロールするメソッドです。`}</p>
    <p>{`データベース中の該当データが更新されたら、新しい`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`ETAG`}</code>{`を作成するコードは以下のようになります。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}>{`fresh_when`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token symbol"
          }}>{`etag`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token variable"
          }}>{`@job_offer`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`@job_offer`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`facility`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <h3>{`model から ETAG をどう作成するか`}</h3>
    <p>{`model の cache_key から ETAG を作ります。`}</p>
    <p>{`該当ページで使用する job_offer モデルの cache_key は`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`"job_offer/5-20071224150000"`}</code>{`のような感じになります。`}</p>
    <p>{`model の id と updated_at の組み合わせでユニークな cache_key を作成しています。`}</p>
    <p><a parentName="p" {...{
        "href": "https://github.com/rails/rails/blob/v4.2.10/activerecord/lib/active_record/integration.rb#L55"
      }}>{`Rails の該当コード`}</a></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`def`}</span>{` `}<span parentName="code" {...{
            "className": "token method-definition"
          }}><span parentName="span" {...{
              "className": "token function"
            }}>{`cache_key`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`*`}</span>{`timestamp_names`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`case`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`when`}</span>{` new_record`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`?`}</span>{`
    `}<span parentName="code" {...{
            "className": "token string-literal"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`"`}</span><span parentName="span" {...{
              "className": "token interpolation"
            }}><span parentName="span" {...{
                "className": "token delimiter punctuation"
              }}>{`#{`}</span><span parentName="span" {...{
                "className": "token content"
              }}>{`model_name`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`.`}</span>{`cache_key`}</span><span parentName="span" {...{
                "className": "token delimiter punctuation"
              }}>{`}`}</span></span><span parentName="span" {...{
              "className": "token string"
            }}>{`/new"`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`when`}</span>{` timestamp_names`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`any`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`?`}</span>{`
    timestamp `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` max_updated_column_timestamp`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`timestamp_names`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    timestamp `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` timestamp`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`utc`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`to_s`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`cache_timestamp_format`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    `}<span parentName="code" {...{
            "className": "token string-literal"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`"`}</span><span parentName="span" {...{
              "className": "token interpolation"
            }}><span parentName="span" {...{
                "className": "token delimiter punctuation"
              }}>{`#{`}</span><span parentName="span" {...{
                "className": "token content"
              }}>{`model_name`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`.`}</span>{`cache_key`}</span><span parentName="span" {...{
                "className": "token delimiter punctuation"
              }}>{`}`}</span></span><span parentName="span" {...{
              "className": "token string"
            }}>{`/`}</span><span parentName="span" {...{
              "className": "token interpolation"
            }}><span parentName="span" {...{
                "className": "token delimiter punctuation"
              }}>{`#{`}</span><span parentName="span" {...{
                "className": "token content"
              }}>{`id`}</span><span parentName="span" {...{
                "className": "token delimiter punctuation"
              }}>{`}`}</span></span><span parentName="span" {...{
              "className": "token string"
            }}>{`-`}</span><span parentName="span" {...{
              "className": "token interpolation"
            }}><span parentName="span" {...{
                "className": "token delimiter punctuation"
              }}>{`#{`}</span><span parentName="span" {...{
                "className": "token content"
              }}>{`timestamp`}</span><span parentName="span" {...{
                "className": "token delimiter punctuation"
              }}>{`}`}</span></span><span parentName="span" {...{
              "className": "token string"
            }}>{`"`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`when`}</span>{` timestamp `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` max_updated_column_timestamp
    timestamp `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` timestamp`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`utc`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`to_s`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`cache_timestamp_format`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    `}<span parentName="code" {...{
            "className": "token string-literal"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`"`}</span><span parentName="span" {...{
              "className": "token interpolation"
            }}><span parentName="span" {...{
                "className": "token delimiter punctuation"
              }}>{`#{`}</span><span parentName="span" {...{
                "className": "token content"
              }}>{`model_name`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`.`}</span>{`cache_key`}</span><span parentName="span" {...{
                "className": "token delimiter punctuation"
              }}>{`}`}</span></span><span parentName="span" {...{
              "className": "token string"
            }}>{`/`}</span><span parentName="span" {...{
              "className": "token interpolation"
            }}><span parentName="span" {...{
                "className": "token delimiter punctuation"
              }}>{`#{`}</span><span parentName="span" {...{
                "className": "token content"
              }}>{`id`}</span><span parentName="span" {...{
                "className": "token delimiter punctuation"
              }}>{`}`}</span></span><span parentName="span" {...{
              "className": "token string"
            }}>{`-`}</span><span parentName="span" {...{
              "className": "token interpolation"
            }}><span parentName="span" {...{
                "className": "token delimiter punctuation"
              }}>{`#{`}</span><span parentName="span" {...{
                "className": "token content"
              }}>{`timestamp`}</span><span parentName="span" {...{
                "className": "token delimiter punctuation"
              }}>{`}`}</span></span><span parentName="span" {...{
              "className": "token string"
            }}>{`"`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`else`}</span>{`
    `}<span parentName="code" {...{
            "className": "token string-literal"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`"`}</span><span parentName="span" {...{
              "className": "token interpolation"
            }}><span parentName="span" {...{
                "className": "token delimiter punctuation"
              }}>{`#{`}</span><span parentName="span" {...{
                "className": "token content"
              }}>{`model_name`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`.`}</span>{`cache_key`}</span><span parentName="span" {...{
                "className": "token delimiter punctuation"
              }}>{`}`}</span></span><span parentName="span" {...{
              "className": "token string"
            }}>{`/`}</span><span parentName="span" {...{
              "className": "token interpolation"
            }}><span parentName="span" {...{
                "className": "token delimiter punctuation"
              }}>{`#{`}</span><span parentName="span" {...{
                "className": "token content"
              }}>{`id`}</span><span parentName="span" {...{
                "className": "token delimiter punctuation"
              }}>{`}`}</span></span><span parentName="span" {...{
              "className": "token string"
            }}>{`"`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span></code></pre></div>
    <h3>{`自前の作成したクラスから ETAG をどう作成するか`}</h3>
    <p>{`model だけではなく、自前で作成したクラスでデータ管理をしてるところもあります。
そちらでの実現方法も試してみました。`}</p>
    <p>{`そこで`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`ETAG`}</code>{`を作るコードが Rails でどうなっているか追ってみました。`}</p>
    <p><a parentName="p" {...{
        "href": "https://github.com/rails/rails/blob/v4.2.10/activesupport/lib/active_support/cache.rb#L92"
      }}>{`Rails の該当コード`}</a></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`def`}</span>{` `}<span parentName="code" {...{
            "className": "token method-definition"
          }}><span parentName="span" {...{
              "className": "token function"
            }}>{`retrieve_cache_key`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`key`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`case`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`when`}</span>{` key`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`respond_to`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`?`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token symbol"
          }}>{`:cache_key`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`then`}</span>{` key`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`cache_key
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`when`}</span>{` key`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`is_a`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`?`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token builtin"
          }}>{`Array`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`            `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`then`}</span>{` key`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`map `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{`element`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` retrieve_cache_key`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`element`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`to_param
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`when`}</span>{` key`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`respond_to`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`?`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token symbol"
          }}>{`:to_a`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`      `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`then`}</span>{` retrieve_cache_key`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`key`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`to_a`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`else`}</span>{`                                  key`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`to_param
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`to_s
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span></code></pre></div>
    <p>{`このように、自作クラスに`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`cache_key`}</code>{`のメソッドを定義したら、その結果から ETAG が作成されるようになっています。このメソッドを使って、ユニークな値を返せば大丈夫です。`}</p>
    <p>{`ここまで調査したことを踏まえて、該当ページを HTTP Cache で実装をしてみました。以下が該当の疑似コードになります。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`class`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`JobOfferBrowsingHistory`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`def`}</span>{` `}<span parentName="code" {...{
            "className": "token method-definition"
          }}><span parentName="span" {...{
              "className": "token function"
            }}>{`cache_key`}</span></span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# return job offer ids`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span></code></pre></div>
    <p>{`fresh_when に渡す`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}><span parentName="code" {...{
            "className": "token variable"
          }}>{`@user_history`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`JobOfferBrowsingHistory`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{`
fresh_when`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token symbol"
          }}>{`etag`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token variable"
          }}>{`@job_offer`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`@job_offer`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`facility`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`@user_history`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <h3>{`テスト`}</h3>
    <p>{`開発環境で該当ページを 2 回ロードしました。
1 回目は 1200ms、キャッシュが効いた 2 回目は 130ms になりました。すごく早いです！
もちろん、ページ内のデータが更新されたら、最新の内容がページに反映されるようにもなっています。`}</p>
    <p>{`これで「ページのデータが更新されたら、最新の内容をすぐユーザへ反映する」「更新がなければ HTTP Cache を返す」という状態が実現しました。`}</p>
    <h3>{`課題`}</h3>
    <ul>
      <li parentName="ul">{`実装ミスで、更新が必要なのに、更新されない問題が起こりえる`}
        <ul parentName="li">
          <li parentName="ul">{`例えば、画面に新しい model を追加したが、`}<code parentName="li" {...{
              "className": "language-text"
            }}>{`fresh_when`}</code>{`に渡すのを忘れたとか`}</li>
        </ul>
      </li>
      <li parentName="ul">{`問題なく更新されることを保証する仕組みの実装`}</li>
    </ul>
    <h1>{`まとめ`}</h1>
    <p>{`HTTP Cache を利用して、ジョブメドレーのスピード改善を検討してみた調査過程を紹介しました。`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`expire_in`}</code>{`、`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`ETAG`}</code>{`の作成方法など色々調べて、最終的に`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`fresh_when`}</code>{`で実現できましたが、運用していく上での課題については、引き続き検討して行きたいと思います。`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      